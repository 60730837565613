exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-editing-tsx": () => import("./../../../src/pages/editing.tsx" /* webpackChunkName: "component---src-pages-editing-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-articles-about-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/articles/about.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-articles-about-mdx" */),
  "component---src-pages-private-profile-tsx": () => import("./../../../src/pages/private/profile.tsx" /* webpackChunkName: "component---src-pages-private-profile-tsx" */),
  "component---src-pages-private-settings-tsx": () => import("./../../../src/pages/private/settings.tsx" /* webpackChunkName: "component---src-pages-private-settings-tsx" */)
}

