import { red } from '@mui/material/colors';
import {createTheme, responsiveFontSizes} from '@mui/material/styles';

// A custom theme for this app
const theme = responsiveFontSizes(createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#004574',
    },
    secondary: {
      main: '#ffeb3b',
      contrastText: '#004574',
    },
    error: {
      main: red.A400,
    },
    text: {

    }
  },
  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
          marginRight: 16,
        }
      }
    }
  },
}));

export default theme;
